import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
// import { Link, navigate } from 'gatsby'

// if (typeof window !== 'undefined') {
//   window.onload = () => {
//     if (typeof ClipboardJS !== 'undefined') {
//       const clipboard = new ClipboardJS('.clip');
//       clipboard.on('success', function(e) {
//         document.getElementById('tooltip').classList.add('show');
//         setTimeout(function () { 
//           document.getElementById('tooltip').classList.remove('show');
//         }, 4000);
//         e.clearSelection();
//       });
//     }
//   }
// }

export const CartSummaryWrapper = styled.div`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
  'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
  'Segoe UI Emoji', 'Segoe UI Symbol';
  opacity: .5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #595C62;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
  }
  p {
    text-align: left!important;
  }
  &.no-padding {
    padding: 0 0 5px;
  }
  &.no-margin {
    margin: 0 0 10px;
  }
  a {
    width: 50%;
    text-align: center;
    border: 1px solid #67471f;
    padding: 1px 5px;
    border-radius: 3px;
    color: #67471f!important;
    font-weight: 500!important;
    transition: color .2s ease-out;
    font-style: normal;
    display: block;
    line-height: 20px;
    transition: color .2s ease-out, background .2s ease-out;
    &:hover {
      text-decoration: none;
      background: #67471f;
      color: #fff!important;
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      display: block;
      text-align: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 1rem 0;
    font-size: 13px;
    margin: 0 0
  }
  
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background: #fff;
    border-bottom: 0;
    border-top: 0;
    padding: .5rem;
    > div {
      max-width: 1072px;
      margin: 0 auto;
      padding: 0 2rem;
      .description {
        display: none;
      }
      a.cartHandler {
        text-align: center;
        display: block;
        ${'' /* width: 100%; */}
      }
      > div {
        padding: 0 5px;
        @media (max-width: ${props => props.theme.breakpoints.xs}) {
          padding: 0 0;
        }
      }
    }
  }
  &.active {
    background: #67471f;
    a {
      color: #fff!important;
    }
  }
  transition: background-color .5s ease-out, opacity .5s ease-out;
`;

const CartInner = styled.div`
  display: block;
  > div {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 450px;
  }
  p {
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    color: #1C252B;
    max-width: 700px;
    margin: 0;

    span {
      text-transform: none;
      padding-top: 5px;
      &#email {
        border-bottom: 1px solid;
        cursor: pointer;
      }
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        &.subtext {
          display: block;
          padding-bottom: 5px;
        }
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      margin-bottom: 1rem;
      font-size: 14px;
    }
    a {
      margin-left: 0;
      font-weight: normal!important;
      font-style: italic;
    }
    i {
      display: inline-block;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        display: inline;
      }
      padding-top: 5px;
      text-transform: none;
    }
  }
  strong {
    color: #67471f;
  }
  button {
    width: 50%;
    margin: 0 15px  0 0;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #9d9d9d;
    background: transparent;
    border-radius: 3px;
    padding: 1px 5px;
    line-height: 20px;
    transition: color .2s ease-out, background .2s ease-out;
    outline: none;
    &:hover {
      background: #9d9d9d;
      color: #fff;
      border: 1px solid #9d9d9d;
    }
  }
`;

function toggleFullScreen() {
  if (typeof document !== 'undefined') {
    if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
          document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
      }
    }
  }
}

const CartComponent = ({ count, letter }) => (
  <CartInner>
    <button style={{ margin: 0, width: '100%' }} onClick={toggleFullScreen}>TOGGLE FULLSCREEN</button> {` `}
    <div>
      {/* {
        (typeof document !== 'undefined') && (
          <React.Fragment>
            <button style={{ marginRight: letter ? '0' : '15px', width: letter ? '100%' : '50%' }} onClick={toggleFullScreen}>TOGGLE FULLSCREEN</button> {` `}
          </React.Fragment>
        )
      } */}
      {/* {
        !letter && (
          <Link to={`/cart/?catalog=1`} className="cartHandler">
            My shopping cart ({`${count} item${count > 1 ? "s" : ""}`})
          </Link>
        )
      } */}
    </div>
    {/* <div>2021/2022</div> */}
  </CartInner>
);

class CatalogCartSummary extends React.Component {

  state = {
    fixed: false
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener("scroll", this.onScrollEvent);
    }
    this.countItems = this.props.items.length;
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener("scroll", this.onScrollEvent)
    }
  }

  componentDidUpdate() {
    if (typeof document !== 'undefined') {
      if (this.countItems !== this.props.items.length) {
        this.countItems = this.props.items.length;
        const el = document.querySelector(`#cart-summary`);
        if (el) {
          const currentClassname = el.className;
          el.className = `${currentClassname} active`;
          setTimeout(() => {
            el.className = `${currentClassname}`;
          }, 500);
        }
      }
    }
  }

  onScrollEvent = () => {
    if (!this.props.className) {
      if (window.scrollY > 215) {
        this.setState({ fixed: true })
      } else {
        this.setState({ fixed: false })
      }
    }
  }

  render() {
    const { items, className, letter } = this.props;
    const count = items.length;
    return (
      <React.Fragment>
        {
          this.state.fixed && (
            <CartSummaryWrapper id="cart-summary" className={`fixed`}>
              <CartComponent count={count} letter={letter} />
            </CartSummaryWrapper>
          )
        }
        <CartSummaryWrapper className={className}>
          <CartComponent count={count} letter={letter} />
        </CartSummaryWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.cart
});
export default connect(mapStateToProps)(CatalogCartSummary);
