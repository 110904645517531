import React from 'react'
import styled from '@emotion/styled'
import TimelineListItem from './TimelineListItem'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style-type: none;
  margin-left: 0;
  flex: 1;
  justify-content: center;
  
  &:after {
    display: block;
    content: "";
    float: none;
    clear: both;
  }
`

export default ({edges}) => (
  <List>{edges.map(filter => <TimelineListItem key={filter.node.uid} node={filter.node} />)}</List>
)
