import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Item = styled.li`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
  float: left;
  margin-bottom: 0;
  letter-spacing: 2px;
  width: 250px;
  height: 250px;
  
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 25%; 
    height: 50px;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 25%; 
    height: 50px;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xsLandscape}) {
    width: 25%; 
    height: 50px;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 33.33%;
    height: 50px;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size:22px
  }

  > a {
    font-size: 28px;
    display:block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    > div:not(.overlay) {
      width: 100%!important;
      height: 100%!important;
      display:flex;
      align-items: center;
      justify-content: center;
    }

    a {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      .overlay {
        visibility:hidden;
        div {
          display: none;
        }
      }
    }
  }
  
`
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(242, 242, 242, 1);
  z-index: 1;
  visibility:visible;

  div {
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }
`

export default (props) => (
  <Item>
    <Link to={`/works/${props.node.uid}/`}>
      <Overlay className="overlay">
        <div>{props.node.data.title.text}</div>
      </Overlay>
      <Img
        fixed={props.node.data.items[0].item.document[0].data.item.localFile.childImageSharp.fixed}
        placeholderStyle={{
          filter: "grayscale(100%) contrast(100%)",
          imageRendering: "pixelated",
          opacity: 0
        }}
      />
    </Link>
  </Item>
)
