import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import theme from '../styles/theme'

const MenuItemContainer = styled.li`
  &:hover {
    ul {
      display: flex!important;
    }
  }
`
const MenuItem = styled(Link)`
  color: ${props => props.active}!important;
  &:hover {
    color: ${theme.colors.black}!important;
  }
`
const SubMenuContainer = styled.ul`
  display: ${props => props.active}!important;
`

export default (props) => {
  const item = props.shop
    ? { uid: "shop", data: { name: "Acquiring" } }
    : props.item.document[0];
  return (
    <MenuItemContainer itemProp="name">
      <MenuItem 
        to={`/${item.uid}/`} 
        active={(props.slug === item.uid || props.parent === item.uid) ? theme.colors.black : theme.colors.light}
        id={`nav-${item.uid}`} 
        itemProp="url"
      >
        {item.data.name}
      </MenuItem>
      { item.data.children && (
        <SubMenuContainer active={(props.parent === item.uid || props.slug === "works") ? 'flex' : 'none'}>
          { item.data.children.map((child, index) => {
            return (
              <li key={`submenu-item-${index}`}>
                <MenuItem 
                  to={`/${child.item.document[0].uid}/`} 
                  active={(props.slug === child.item.document[0].uid) ? theme.colors.black : theme.colors.light} 
                  itemProp="url"
                >
                  {child.item.document[0].data.name}
                </MenuItem>
              </li> 
            )
          })}
        </SubMenuContainer>
      )}
    </MenuItemContainer>
  );
}