import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

const Image = ({ input }) => (
  <Content>
    <Img 
      fluid={input.primary.image.localFile.childImageSharp.fluid} 
      placeholderStyle={{
        filter: "grayscale(100%) contrast(100%)",
        imageRendering: "pixelated",
        opacity: ".2"
      }}
    />
  </Content>
)

export default Image

Image.propTypes = {
  input: PropTypes.object.isRequired,
}
