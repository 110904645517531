import React from 'react'
import styled from '@emotion/styled'
import CatalogFooterImg from '../../static/catalog-footer.png'
import CatalogFaFooterImg from '../../static/catalog-fa-footer.jpeg'

const Wrapper = styled.div`
  max-width: 1440px;
  padding: 0 3rem;
  margin: 0 auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
    padding: 0 1rem;
  }
  > div {
    width: 50%;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
      p{
        text-align: center;
      }
    }
    p {
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 
      'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 
      'Segoe UI Emoji', 'Segoe UI Symbol';
      margin: 2rem 0;
      a {
        color: #67471f;
        font-style: normal;
        font-weight: 600;
      }
      small {
        display: block;
      }
      span {
        display: block;
        margin-top: .5rem;
      }
    }
    &:last-of-type {
      p {
        text-align: right;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          text-align: center;
        }
      }
    }
  }
`

const imgs = {
  'fonds-d-atelier': CatalogFaFooterImg,
  '2021-2022': CatalogFooterImg
};

const CatalogFooter = (props) => {
  
  return (
    <Wrapper>
      <div>
        <p>
          Indicated prices are the "workshop prices".<br />
          (without gallery fees)
        </p>
        <p>
          You can pay in 3 or 4 times for works whose price is higher than 1500 €.<br />
          For more informations, feel free to <a href="mailto:dischr@gmail.com" title="dischr@gmail.com">contact us</a>.
        </p>
      </div>

      <div>
        <p>
          Contact : <strong style={{ color: '#67471f' }}>Rémy Disch</strong> <br />
          Email: <a href="mailto:dischr@gmail.com">dischr@gmail.com</a><br />
        </p>
        <p>
          Official website : <br /><a href="https://www.dischr.com/" target="_blank">https://www.dischr.com</a><br />
          Online shop : <br /><a href="https://shop.dischr.com/" target="_blank">https://shop.dischr.com</a><br />
          Patreon : <br /><a href="https://www.patreon.com/remy_disch/" target="_blank">https://www.patreon.com/remy_disch/</a><br />
        </p>
        <p>
          Partnerships<br />
          <a href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">ARTSPER</a><br />
          <a
            href="https://www.gazette-drouot.com/recherche/lot/disch-remy?type=past&modeDiapo=&query=DISCH+R%C3%A9my&actuDatefilter=&exactMatch=false&order=dateDesc"
            target="_blank"
          >
            LA GAZETTE DROUOT
          </a>
        </p>
        <p>
          Follow me on : <br />
          <a href="https://www.instagram.com/dischremy/" target="_blank">Instagram</a> <br />
        </p>
        <div>
          <img src={imgs[props.data.uid]} />
          <p>
            Artiste côté chez <strong>DROUOT COTATION</strong>
            <span>
              LA MAISON DES ARTISTES <br />
              SIRET 52852265900010
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default CatalogFooter;
