import React from 'react'
import styled from '@emotion/styled'
import InstagramListItem from './InstagramListItem'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style-type: none;
  margin-left: 0;
  flex: 1;
  justify-content: center;
  
  &:after {
    display: block;
    content: "";
    float: none;
    clear: both;
  }
`

export default ({ posts }) => {
  return (
    <List>
      {posts.map((post, index) => {
        return (
          <InstagramListItem key={`instagram-post-${index}`} node={post.node} />
        )
      })}
    </List>
  )
}
