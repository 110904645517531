import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListItem from './ListItem'
import { handleModalBanner } from '../../actions/modal'
import ModalArtwork from '../Modal/ModalArtwork'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style-type: none;
  margin-left: 0;
  flex: 1;
  justify-content: center;
  
  &:after {
    display: block;
    content: "";
    float: none;
    clear: both;
  }
`

const Listing = ({ posts, modalBanner, handleModalBanner, eshop }) => {
  return (
    <React.Fragment>
      <List style={{
        opacity: modalBanner ? 0 : 1,
        transition: 'opacity 200ms ease-in'
      }}>
        {posts.map((post, index) => {
          return (
            <ListItem
              key={post.node.uid}
              onClick={() => handleModalBanner({
                modalBanner: !modalBanner,
                initialSlide: index
              })}
              node={post.node}
              eshop={eshop}
            />
          )
        })}
      </List>
      <ModalArtwork images={posts} />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(Listing);

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}
