import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import WorkshopListItem from './WorkshopListItem'
import ModalArtwork from '../Modal/ModalArtwork'
import { handleModalBanner } from '../../actions/modal'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 2rem;
  list-style-type: none;
  margin-left: 0;
  flex: 1;
  justify-content: center;
  
  &:after {
    display: block;
    content: "";
    float: none;
    clear: both;
  }
`

const WorkshopListing = ({ posts, modalBanner, handleModalBanner }) => {
  return (
    <React.Fragment>
      <List>
        {posts.map((post, index) => {
          return (
            <WorkshopListItem 
              onClick={() => handleModalBanner({
                modalBanner: !modalBanner,
                initialSlide: index
              })}
              key={`workshop-post-${index}`} 
              node={post}
            />
          )
        })}
      </List>
      <ModalArtwork images={posts} />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkshopListing);