import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import {
  ProductInfos,
  NotAvailable,
  AddToCart
} from '../templates/catalogItemStyle'
import { handleModalBanner } from '../actions/modal'
import { addItem, removeItem } from '../actions/cart';
import "../styles/magnify.css";

class CatalogItemDescription extends React.Component {

  state = {
    width: '100%'
  }

  componentDidMount() {
    const prismic = this.props.currentArtwork.document[0];
    const description = prismic.data.cadre.localFile
      ? `${prismic.data.type} - ${prismic.data.cadre.alt}`
      : prismic.data.type;

    const isFondAtelier = prismic.data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;

    // const reduction = isFondAtelier ? 0 : 10;
    const reduction = 0;
    const price = reduction > 0 ? (
      parseInt(prismic.data.price) - parseInt(prismic.data.price) * 10 / 100
    ) : prismic.data.price

    this.data = {
      id: prismic.prismicId,
      uid: prismic.uid,
      title: prismic.data.title.text,
      description: description,
      amount: price,
      reduction: reduction
    }

    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      this.attachedItem = document.querySelector(`#${this.props.elementId}`);
      window.addEventListener('resize', this.manageLayout);
      this.manageLayout();
    }
  }

  manageLayout = () => {
    setTimeout(() => {
      this.setState({
        width: this.attachedItem ? this.attachedItem.clientWidth : '100%'
      })
    }, 3000);
  }

  removeFromCart = () => {
    this.props.removeItem(this.data);
  }

  addToCart = () => {
    this.props.addItem(this.data);
  }

  render() {

    const {
      currentArtwork: { document: [{
        prismicId, data
      }] },
      count,
      pclass,
      cartItems,
      firebaseOrders,
    } = this.props;

    const isInCart = cartItems.filter(i => i.id === prismicId).length === 1;
    const isOrdered = firebaseOrders.length > 0 && firebaseOrders.filter(i => i === prismicId).length === 1
    const isFondAtelier = data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;
    return (
      <ProductInfos 
        className={`${pclass} outside ${isOrdered ? "isOrdered" : ""}`}
        style={{
          width: this.state.width
        }}
      >
        <div>
          <h2>
            <div>{data.title.text}</div>
            {(JSON.parse(data.available) === false || isOrdered) && (
              <NotAvailable />
            )}
          </h2>
          <span className="type">{data.type}</span>
          <span className="categories">
            <small>categories: </small>
            <small>
              <Link to={`/works/`}>#works</Link>
            </small>
            {data.categories.map((item, index) => {
              return (
                <small key={`tag-${index}`}>
                  <Link to={`/${item.category.uid}/`}>
                    #{item.category.document[0].data.name}
                  </Link>
                </small>
              );
            })}
            {data.year && (
              <small>
                <Link to={`/works/${data.year.uid}/`}>
                  #{data.year.uid}
                </Link>
              </small>
            )}
          </span>

          {!this.props.isOrdered && JSON.parse(data.available) && (
            <AddToCart>
              {JSON.parse(data.available) && !this.props.isOrdered && (
                <React.Fragment>
                  <div className="price">
                    {
                      isFondAtelier ? (
                        <React.Fragment>{data.price}.00 € </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {/* <span style={{
                            display: 'inline-block',
                            textDecoration: 'line-through',
                            opacity: .8,
                            paddingRight: '.5rem'
                          }}>{parseInt(data.price)}.00 €</span>
                          {` `}{parseInt(data.price) - parseInt(data.price) * 10/100}.00 € */}
                          {parseInt(data.price)}.00 €
                        </React.Fragment>
                      )
                    }
                    {` `}<small>(Shipping fee included)</small>
                  </div>
                </React.Fragment>
              )}
              {
                data.drouot_estimate && (
                  <span className="drouot-estimate">
                    (<strong>Drouot estimate :</strong> {data.drouot_estimate} €)
                  </span>
                )
              }
              {data.on_demand ? (
                <span className="isOrdered">
                  On demand, contact my agent at davidbraud@dischr.com
                </span>
              ) : (
                <React.Fragment>
                  {/* {isInCart ? (
                    <button
                      className="alreadyInCart"
                      onClick={this.removeFromCart}
                    >
                      Remove from cart
                    </button>
                  ) : (
                    <React.Fragment>
                      {this.props.isOrdered ||
                        JSON.parse(data.available) === false ? (
                        <button className="isOrdered">Sold</button>
                      ) : (
                        <button onClick={this.addToCart}>Add to cart</button>
                      )}
                    </React.Fragment>
                  )} */}
                </React.Fragment>
              )}
            </AddToCart>
          )}
        </div>
      </ProductInfos>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart,
  firebaseOrders: state.order.items,
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
  removeItem: (item) => dispatch(removeItem(item)),
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(CatalogItemDescription);
