import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import {
  ImageContainer,
  ShopImageWrapper,
  ProductInfos,
  NotAvailable,
  AddToCart,
  ProductWrapper,
  ProductInner
} from '../templates/catalogItemStyle'

import { handleModalBanner } from '../actions/modal'

class CatalogItemMobile extends React.Component {

  state = {
    loading: false,
    zoomInstance: null
  }

  componentDidMount() {
    const prismic = this.props.currentArtwork.document[0];
    const description = prismic.data.cadre.localFile
      ? `${prismic.data.type} - ${prismic.data.cadre.alt}`
      : prismic.data.type;

    const isFondAtelier = prismic.data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;
    
    const reduction = isFondAtelier ? 0 : 0;
    const price = reduction > 0 ? (
      parseInt(prismic.data.price) - parseInt(prismic.data.price) * 10 / 100
    ) : prismic.data.price

    this.data = {
      id: prismic.prismicId,
      uid: prismic.uid,
      title: prismic.data.title.text,
      description: description,
      amount: price,
      reduction: reduction
    }
  }

  render() {

    const {
      currentArtwork: { document: [{
        prismicId, data
      }] },
      count,
      id,
      firebaseOrders,
      elementId,
      hideprice,
      hd,
      cadre,
      noPadding
    } = this.props;
    
    const isOrdered = firebaseOrders.length > 0 && firebaseOrders.filter(i => i === prismicId).length === 1
    
    const isFondAtelier = data.categories.filter((item) => {
      return item.category.uid === 'fonds-datelier';
    }).length > 0;
    
    return (
      <React.Fragment>
        <div 
          id={elementId}
          style={{
            visibility: this.state.loading ? 'hidden' : 'visible'
          }}
          className={`col-full`}
        >
          <ProductWrapper 
            className={'col-full'} 
            style={{ padding: noPadding ? '0' : '0 1rem' }}
          >
            <ProductInner className={'default'}>
              <ImageContainer className="full">
                <ShopImageWrapper>
                  <img 
                    className="xzoom"
                    src={data.cadre.localFile ? (
                      data.cadre.localFile.childImageSharp.fluid.src
                    ) : (
                      data.item.localFile.childImageSharp.fluid.src
                    )}
                    xoriginal={data.image_hd.localFile ? (
                      data.image_hd.localFile.childImageSharp.original.src
                    ) : (
                      data.item.localFile.childImageSharp.fluid.src
                    )}
                  />
                </ShopImageWrapper>
              </ImageContainer>
              {
                !hideprice && (
                  <ProductInfos 
                    className={isOrdered ? "isOrdered" : ""} 
                    style={{
                      paddingBottom: `${(count > 1 && id === 0) ? '15rem' : 0}`
                    }}
                  >
                    <div>
                      <h2>
                        <div>{data.title.text}</div>
                        {(JSON.parse(data.available) === false || isOrdered) && (
                          <NotAvailable />
                        )}
                      </h2>
                      <span className="type">{data.type}</span>
                      <span className="categories">
                        <small>categories: </small>
                        <small>
                          <Link to={`/works/`}>#works</Link>
                        </small>
                        {data.categories.map((item, index) => {
                          return (
                            <small key={`tag-${index}`}>
                              <Link to={`/${item.category.uid}/`}>
                                #{item.category.document[0].data.name}
                              </Link>
                            </small>
                          );
                        })}
                        {data.year && (
                          <small>
                            <Link to={`/works/${data.year.uid}/`}>
                              #{data.year.uid}
                            </Link>
                          </small>
                        )}
                      </span>

                      {!this.props.isOrdered && JSON.parse(data.available) && (
                        <AddToCart>
                          {JSON.parse(data.available) && !this.props.isOrdered && (
                            <React.Fragment>
                              <div className="price">
                                {
                                  isFondAtelier ? (
                                    <React.Fragment>{data.price}.00 € </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {parseInt(data.price)}.00 €
                                    </React.Fragment>
                                  )
                                }
                                {` `}<small>(Shipping fee included)</small>
                              </div>
                            </React.Fragment>
                          )}
                          {
                            data.drouot_estimate && (
                              <span className="drouot-estimate">
                                (<strong>Drouot estimate :</strong> {data.drouot_estimate} €)
                              </span>
                            )
                          }
                        </AddToCart>
                      )}
                    </div>
                  </ProductInfos>
                )
              }
            </ProductInner>
          </ProductWrapper>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  firebaseOrders: state.order.items,
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(CatalogItemMobile);
