import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import i18n from '../../../config/i18n'


const SEO = ({ title, desc, banner, pathname, article, locale, modalBanner }) => {
  const { site, file } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteLanguage, headline } = i18n[locale]

  const {
    buildTime,
    siteMetadata: { siteUrl, defaultBanner, ogLanguage, author, facebook },
  } = site

  const localizedPath = i18n[locale].default ? '' : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`
  // console.log(site);

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${homeURL}${pathname}`,
    headline: title || headline.headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: `${homeURL}${pathname}`,
    description: desc || defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-08-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial SiteNavigation
  const itemListElement = [
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-works",
      "name": "Works",
      "url": `${homeURL}/works/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-workshop",
      "name": "Workshop",
      "url": `${homeURL}/worshop/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-exhibition",
      "name": "Exhibition",
      "url": `${homeURL}/exhibition/`
    },
    {
      "@context": "https://schema.org",
      "@type": "SiteNavigationElement",
      "@id": "#nav-contact",
      "name": "Contact",
      "url": `${homeURL}/contact/`
    }
  ]

  const SiteNavigation = {
    '@context': 'http://schema.org',
    "@graph": itemListElement
  }

  return (
    <>
      <Helmet 
        title={seo.title} 
        style={[{
          "cssText": `
            html,
            body {
              position: ${modalBanner ? 'fixed' : 'relative'};
              height: ${modalBanner ? '100%' : 'auto'}
              overflow: ${modalBanner ? 'hidden!important' : 'auto'}
            }
          `
        }]}
      >
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="title" content={seo.title} />
        <link rel="canonical" href={seo.url}></link>
        <link rel="icon" type="image/png" href={file.publicURL} />
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(SiteNavigation)}</script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.4/clipboard.min.js"></script>
        <script defer data-domain="dischr.com" src="https://plausible.io/js/script.js"></script>
      </Helmet>
      
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
export default connect(mapStateToProps)(SEO);

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  locale: 'en-us',
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        author
        facebook
      }
    }
    file(name: {eq: "favicon"}) {
      publicURL
    }
  }
`