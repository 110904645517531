import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Menu, MobileMenu } from '../components'

const Hero = styled.header`
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
  > div {
    div {
      &:last-of-type {
        display: none;
        @media (max-width: ${props => props.theme.breakpoints.m}) {
          display: block;
        }
      };
    }
  }
`
const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 0 0 1.5rem;
  }
`
const HeroInner = styled(Wrapper)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  h1, p.h1 {
    margin: 0;
    padding: 0.5em;
    font-size: 30px;
    line-height: 0.8em;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      text-align: left;
      padding-left: 0;
    }
    a {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      text-decoration: none;
    }
    span {
      font-weight: 200
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
`

const Header = (props) => {
  return (
    <React.Fragment>
      <Hero>
        <MobileMenu {...props} />
        <HeroInner>
          <p className="h1">
            <Link to="/">Disch <span>Remy</span></Link>
          </p>
          <Menu {...props} />
        </HeroInner>
      </Hero>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  items: state.cart
});

export default connect(mapStateToProps)(Header);