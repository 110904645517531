import styled from '@emotion/styled'

export const ImageContainer = styled.div`
  position: relative;
  width: 50%;
  height: auto;
  &.full {
    max-width: 100%;
    width: 100%;
  }
  > div {
    img.xzoom {
      width: 100%;
      box-shadow: 0px 0px 0 0px rgb(0 0 0 / 0%);
    }
  }
  padding: 0;
  &.content {
    padding: 0 2rem;
    .gatsby-image-wrapper {
      picture {
        img {
          max-width: 100%;
          object-fit: contain!important;
        }
      }
      img {
        object-fit: contain!important;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    padding: 0;
  }
`;

export const ShopImageWrapper = styled.div`
  position: relative;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    .overlay {
      visibility:visible;
    }
  }
  .gatsby-image-wrapper {
    display: block!important;
    width: 100%!important;
    height: 100%!important;
  }
  img.responsiveHeight {
    display: block;
    margin: 0 auto;
    width: auto!important;
    height: calc(100vh - 5rem);

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
      height: auto
    }
  }
`

export const ProductInfos = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    padding: 15px 0;
  }

  &.outside {
    display: block;
    padding: .5rem 1rem 0;
  }

  &.isOrdered {
    h2 div, > span, .price {
      opacity: .6
    }
  }
  div.descriptions {
    padding: 0 1.5rem;
    p {
      font-size: 13px;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    h3 {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
    h6 {
      font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
      text-align: right;
      font-size: 11px;
    }
    &.ltr {
      h3, p {
        text-align: right;
      }
    }
  }
  h2 {
    font-family: 'Roboto',sans-serif;
    display: flex;
    padding: 0;
    margin: .5rem 0 0 0;
    font-size: 16px;
    font-weight: 800;
    color: #1c252b;
    div {
      display: inline
    }
  }

  div.price {
    font-size: 18px;
    color: #999999;
    font-weight: bold;
    small {
      font-size: 11px;
    }
  }
  span {
    display: block;
    &.year {
      margin-bottom: 0;
      font-size: 14px;
      color: #999;
    }
    &.categories {
      margin-top: 0;
      font-size: 13px;
      color: #999;
      small {
        display: inline-block;
        margin-right: 10px;
        a {
          font-size: 13px;
          font-style: normal;
          color: #999;
          font-weight: bold;
        }
      }
    }
    &.type {
      font-size: 16px;
      font-weight: 100;
    }
    &.shipping {
      font-size: 12px
    }
    &.drouot-estimate {
      font-size: 14px
    }
  }
`;

export const NotAvailable = styled.span`
  background: #ff1d01;
  width: 12px;
  height: 12px;
  display: block;
  ${'' /* float: right; */}
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 10px;
`
export const AddToCart = styled.div`
  width: 100%;
  padding: 0;
  ${'' /* margin-top: .5rem; */}

  > .price {
    font-size: 16px;
  }

  button {
    padding: 0;
    margin-top: .5rem;
    font-size: 16px;
    font-family: 'Roboto',sans-serif;
    border: 0;
    border-bottom: 1px solid #494949;
    cursor: pointer;
    transition: color .2s ease-out;
    font-weight: 100;
    background: none;
    outline: none;
    &:hover {
      color: #999999;
      border-bottom: 1px solid #999999;
    }
    &.isOrdered {
      cursor: default;
      color: #999999;
      border-bottom: 1px solid #999999;
    }
  }
`;

export const CatalogWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  display: flex; 
  align-items: flex-end;
  justify-content: space-between;
  
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }

  > div {
    &.col-2 {
      max-width: 50%;
    }
    &.col-3 {
      max-width: 33.33%;
    }
  }
  &.default {
    align-items: center;
    justify-content: center;
    margin-bottom: 15rem;
    > div {
      > div {
        > div {
          > div:first-of-type {
            > div {
              min-width: 400px;
              @media (max-width: ${props => props.theme.breakpoints.s}) {
                min-width: 100%;
                padding-top: 1rem;
              }
              padding-top: 25rem;
              padding-right: .5rem;
            }
          }
        }
      }
    }
  }
  &.fullscreen {
    display: block;
    margin-bottom: 15rem;
    &.letter {
      margin-bottom: 2rem;
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
      padding: 2rem 6rem;
      background: #e7e7e7;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        padding: 2rem 2rem;
      }
    }
  }
  &:not(.description) {
    min-height: calc(100vh - 400px);
  }
  &.description {
    align-items: baseline;
    margin-bottom: 15rem;
  }
  &.acquiring {
    
  }
`
export const ProductWrapper = styled.div`
  max-width: 1440px;
  padding: 0 1rem;
  overflow: hidden;
  margin: 0 auto;
  font-size: 18px;
  
  &.content {
    margin: 0 auto 5rem;
    padding: 5rem 1rem;
  }
  
  .gatsby-image-wrapper {
    max-height: 100%;
    picture {
      img {
        max-width: 100%;
        object-fit: contain!important;
      }
    }
    img {
      max-width: 100%;
      object-fit: contain!important;
    }
  }
  &.col-2 {
    > div {
      display: flex;
      flex-direction: column;
      > div {
        display: block;
        width: 100%;
        padding-right: 0;
      }
    }
  }
  &.col-3 {
    > div {
      display: block;
      > div {
        display: block;
        width: 100%;
        padding-right: 0;
      }
    }
  }
  &.col-full {
    display: block;
    width: 100%;
    > div {
      display: block;
      width: 100%;
      > div {
        display: block;
        width: 100%;
        > div {
          > figure {
            width: 100%;
            > img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  > div {
    > div {
      > div {
        > figure {
          display: block;
        }
      }
    }
  }

  &.banner {
    height: calc(100vh - 200px);
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      height: auto;
    }
    margin-bottom: 5rem;
    padding: 0 3rem;
    .gatsby-image-wrapper {
      height: auto;
      picture {
        img {
          object-fit: cover!important;
        }
      }
      img {
        object-fit: cover!important;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      height: auto;
    }
  }
  
  &.portrait {
    height: calc(100vh);
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      height: auto;
    }
    margin: 0 auto 5rem;
  }

  a {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  p {
    text-align: justify;
    em {
      font-size:18px;
      line-height: 18px
    }
  }
`
export const ProductInner = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;
export const BreadCrumb = styled.div`
font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  ul {
    margin: 0;
    li {
      margin: 0;
      padding-bottom: 15px;
      list-style-type: none;
      display: inline-block;
      font-size: 14px;
      color: #595C62;
      :not(:last-of-type) {
        &:after {
          display: inline-block;
          content: "/";
          padding: 0 5px;
        }
      }
    }
  }
`;