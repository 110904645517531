import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import { push as Menu } from 'react-burger-menu'

const MobileMenuWrapper = styled(Menu)`
  > div {
    nav {
      ul {
        outline: none;
        margin: 0;
        li {
          list-style-type: none;
          a {
            font-family: ${props => props.theme.fontFamily};
            font-style: normal;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 400;
            padding: .5rem;
            display: block!important;
          }

          ul {
            margin: 0 0 0 15px;
            padding: 0;
            li {
              margin: 0;
              padding: 1px 0;
              a {
                padding: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`

const MenuItem = styled(Link)`
  color: ${props => props.active}!important;
`;

var BurgerMenuStyles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '25px',
    top: '35px',
    'zIndex': 2
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0
  },
  bmMenu: {
    background: '#f2f2f2',
    padding: '0.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(242, 242, 242, 0.7)',
    filter: 'blur(1.5rem)',
    top: 0
  }
}

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          menu: allPrismicNavigation {
            edges {
              node {
                data {
                  links {
                    item {
                      document {
                        uid
                        data {
                          name
                          children {
                            item {
                              document {
                                uid
                                data {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const [...menu] = data.menu.edges[0].node.data.links;
        return (
          <MobileMenuWrapper right styles={BurgerMenuStyles}>
            <ul>
              {menu.map((value, index) => {
                const item = value.item.document[0];
                return (
                  <li key={`mobile-menu-item-${index}`}>
                    <MenuItem 
                      to={`/${item.uid}/`}
                      active={(props.slug === item.uid || props.parent === item.uid) ? theme.colors.black : theme.colors.mediumGrey} 
                    >
                      {item.data.name}
                    </MenuItem>
                    {
                      item.data.children.length > 0 && (
                        <ul>
                          {
                            item.data.children.map((v,i) => {
                              const submenuItem = v.item.document[0];
                              return (
                                <li key={`mobile-submenu-item-${item.uid}-${i}`}>
                                  <MenuItem 
                                    to={`/${submenuItem.uid}/`}
                                    active={(props.slug === submenuItem.uid) ? theme.colors.black : theme.colors.mediumGrey} 
                                  >
                                    {submenuItem.data.name}
                                  </MenuItem>
                                </li>
                              )
                            })
                          }
                        </ul>
                      )
                    }
                  </li>
                )
              })}
              <li key={`mobile-menu-item-shop`}>
                <MenuItem 
                  to={`/shop/`}
                  active={(props.slug === 'shop') ? theme.colors.black : theme.colors.mediumGrey} 
                >
                  Shop
                </MenuItem>
              </li>
            </ul>
          </MobileMenuWrapper>
        );
      }}
    />
  )
}