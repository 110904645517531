import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styled from '@emotion/styled'
import theme from '../styles/theme'

const TimelineWrapper = styled.div`
  position: relative;
  min-height: 36px;
  margin-bottom: 2.5rem;
  
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 0;
  }
  ul {
    font-family: ${props => props.theme.fontFamily};
    margin-left:0;
    margin-bottom:0;
    position: absolute;
    display: inline-block;
    width: 100%;
    text-align: center;
    
    li {
      list-style-type: none;
      display: inline-block;
      position: relative;
      margin-bottom: 0;
      text-align: center;
      cursor: pointer;
      span {
        padding: 0 0.5rem;
        font-size: 28px;
        letter-spacing: 2px;
      }
      > ul {
        display: none;
        margin-top:0;
        width: 100%;
        z-index: 2;
        background: rgba(242,242,242,.9);
        li {
          line-height: 2rem
        }
      }
      &:hover {
        ul {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`

const YearWrapper = styled(Link)`
  font-style: normal;
  padding: 0.5rem 1rem;
  color: ${props => props.theme.colors.light}!important;
  font-weight: normal;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.black}!important;
  }
`

export default ({uid}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          filters: allPrismicFilters(
            sort: { 
              fields: [uid], order: DESC 
            },
            filter: { 
              data: {
                availability: { eq: "true" }
              }
            }
          ) {
            edges {
              node {
                uid
              }
            }
          }
        }
      `}
      render={({ filters: { edges: years } }) => {
        const filtered = years.filter(year => year.node.uid !== uid);
        return (
          <TimelineWrapper>
            <ul>
              <li>
                <span>{uid}</span>
                <ul>
                  {filtered.sort().map((year) => (
                    <li key={`year-filter-${year.node.uid}`}>
                      <YearWrapper to={`/works/${year.node.uid}/`}>
                        {year.node.uid}
                      </YearWrapper>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </TimelineWrapper>
        );
      }}
    />
  )
}