import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
// import { Menu, MobileMenu } from '../components'
import CatalogCartSummary from './CatalogCartSummary'

const Hero = styled.header`
  display: block;
  padding: 0 3rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`
const Wrapper = styled.div`
  width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: 100%;
  }
`
const HeroInner = styled(Wrapper)`
  padding-top: 1rem;
  ${'' /* padding-bottom: 1rem; */}
  border-bottom: 1px solid ${props => props.theme.colors.light};
  margin: 0 auto;
  display: block;
  width: 100%;
  display: flex;
  ${'' /* align-items: center; */}
  justify-content: space-between;

  h1, p.h1 {
    margin: 0;
    padding: 0.5em 0 0;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      text-align: left;
      padding-left: 0;
      max-width: 100%;
    }
    a {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      text-decoration: none;
    }
    span {
      font-weight: 200
    }
  }

  p.h2 {
    margin: 0;
    padding: 0.5em 0 0;
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      text-align: left;
      padding-left: 0;
    }
    font-weight: 200;
    color: #494949;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
  }
`

const HeroOuter = styled(Wrapper)`
  margin: 0 auto;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2em 0;
  justify-content: space-between;
  p {
    font-family: 'Roboto', sans-serif;
    color: #494949;
  }
`

const CatalogHeader = (props) => {
  return (
    <React.Fragment>
      <Hero>
        <HeroInner>
          <p className="h1">
            <Link to="/">Disch <span>Remy</span></Link>
          </p>
          <CatalogCartSummary letter={props.letter} />
          <p className="h2">
            {props.letter ? 'La lettre' : 'Catalog'}
          </p>
        </HeroInner>
        <HeroOuter>
          <p>www.dischr.com</p>
          {props.letter ? <p>2024</p> : <p>{props.title}</p>}
        </HeroOuter>
      </Hero>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  items: state.cart
});

export default connect(mapStateToProps)(CatalogHeader);