import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Item = styled.li`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
  float: left;
  margin-bottom: 0;
  width: 250px;
  height: 250px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xsLandscape}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 100%; 
    height: auto;
    padding-top: 100%;
    margin-bottom: 2rem;
  }
  
  > div:not(.overlay) {
    position: absolute!important;
    display: block!important;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
  }

  &:hover {
    .overlay {
      visibility:visible;
    }
  }
`

export default (props) => (
  <Item onClick={() => window.open(`https://www.instagram.com/p/${props.node.id}/`)}>
    <Img 
      fixed={props.node.localFile.childImageSharp.fixed} 
      placeholderStyle={{ 
        filter: "grayscale(100%) contrast(100%)",
        imageRendering: "pixelated", 
        opacity: 0
      }}
    />
  </Item>
)
