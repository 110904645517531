import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { MenuItem } from '../components'

const MenuWrapper = styled.ul`
  font-family: ${props => props.theme.fontFamily};
  border-top: 1px solid ${props => props.theme.colors.light};
  margin: 0;
  display: flex;
  * {
    margin: 0;
    list-style-type: none;
  }
  li {
    font-size: 20px;
    padding: 0.5em 0.8em;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      font-style: normal;
      text-transform: uppercase;
      color: ${props => props.theme.colors.light};
      text-decoration: none;
      font-weight: 400
    }
    ul {
      display: flex;
      position: absolute;
      li {
        font-size: 16px;
        padding: 0.5em;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          menu: allPrismicNavigation {
            edges {
              node {
                data {
                  links {
                    item {
                      document {
                        uid
                        data {
                          name
                          children {
                            item {
                              document {
                                uid
                                data {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const [...menu] = data.menu.edges[0].node.data.links;
        return (
          <React.Fragment>
            <MenuWrapper itemScope itemType="http://www.schema.org/SiteNavigationElement">
              {menu.map((value, index) => (
                <MenuItem key={`menu-item-${index}`} {...props} {...value} />
              ))}
              <MenuItem key={`menu-item-${(menu.length + 1)}`} {...props} shop />
            </MenuWrapper>
          </React.Fragment>
        );
      }}
    />
  )
}