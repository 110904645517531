import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  font-size: 18px;
  padding: 3rem 2rem;
  &.timeline {
    padding: 2rem 2rem 3rem;
  }
  a {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.grey};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    &.regular-link {
      font-size: 1.1rem;
      text-decoration: underline;
      color: #3e4043;
      &:hover {
        color: #3e4043;
        text-decoration: underline;
      }
    }
  }
  p {
    text-align: justify;
    em {
      font-size:18px;
      line-height: 18px
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 1.5rem;
  }
`

export default Wrapper
